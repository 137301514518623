import { lazy } from "react";
import { RouteType } from "./Config";
import ImageSVG from "@components/logo/ImageSGV";
import assets from "@assets/index";
import Suppliers from "../pages/suppliers/Suppliers";
import ProtectedRoute from "./ProtectedRoute";

const AnalitycsPage = lazy(() => import("@pages/dashboard/Analitycs"));
const Users = lazy(() => import("@pages/users/Users"));
const Categories = lazy(() => import("@pages/categories/Categories"));
const Supplies = lazy(() => import("@pages/products/Supplies"));
const Products = lazy(() => import("@pages/products/Products"));
const Movements = lazy(() => import("@pages/inventory/Movements"));
const Kardex = lazy(() => import("@pages/inventory/Kardex"));
const Orders = lazy(() => import("@pages/orders/Orders"));
const Production = lazy(() => import("@pages/production/Production"));
const Cash = lazy(() => import("@pages/sales/cash/Cash"));

const CatalogSunatSetting = lazy(() => import("@pages/settings/catalog/CatalogSunatSetting"));
const Establishments = lazy(() => import("@pages/establishments/Establishments"));
const Rooms = lazy(() => import("@pages/rooms/Rooms"));
const PointOfSale = lazy(() => import("@pages/pos/PointOfSale"));
const Productions = lazy(() => import("@pages/company/Production"));
const Customers = lazy(() => import("@pages/customers/Customers"));
const CompanySetting = lazy(() => import("@pages/company/CompanySetting"));

const DashboardPageLayout = lazy(() => import("@pages/dashboard/DashboardPageLayout"));
const HomePage = lazy(() => import("@pages/home/Home"));
const Box = lazy(() => import("@pages/box"));
const Reportes = lazy(() => import("@pages/reportes/Dashboard"));
const AccountsxPayable = lazy(() => import("@pages/contabilidad/AccountsxPayable"));

const appRoutes: RouteType[] = [
  // {
  // 	index: true,
  // 	element: <HomePage />,
  // 	state: "home",
  // },

  {
    path: "/dashboard",
    element: <DashboardPageLayout />,
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <ImageSVG image={assets.images.Dashboard} />,
    },
    child: [
      {
        path: "/dashboard/analitycs",
        element: <AnalitycsPage />,
        state: "dashboard.analitycs",
        sidebarProps: { displayText: "Analitycs Dashboard" },
      },
    ],
  },
  {
    path: "box",
    element: <Box />,
    state: "box",
    sidebarProps: {
      displayText: "caja",
      icon: <ImageSVG image={assets.images.cajaRegistradora} />,
    },
  },
  {
    path: "pointOfSale",
    element: <PointOfSale />,
    state: "pointOfSale",
    sidebarProps: {
      displayText: "Punto de venta",
      icon: <ImageSVG image={assets.images.posTerminal} />,
    },
  },
  {
    path: "production",
    element: <Production />,
    state: "production",
    sidebarProps: {
      displayText: "Producción",
      icon: <ImageSVG image={assets.images.mozo} />,
    },
  },
  {
    path: "sales",
    element: <DashboardPageLayout />,
    state: "sales",
    sidebarProps: {
      displayText: "Ventas",
      icon: <ImageSVG image={assets.images.area} />,
    },
    child: [
      {
        path: "/sales/orders",
        element: <PointOfSale />,
        state: "sales.orders",
        sidebarProps: {
          displayText: "Ordenes & Pedidos",
        },
      },
      {
        path: "/sales/list",
        element: <Orders />,
        state: "sales.list",
        sidebarProps: {
          displayText: "Comprobantes",
        },
      },
      {
        path: "/sales/cash",
        element: <Cash />,
        state: "sales.cash",
        sidebarProps: { displayText: "Caja Chica" },
      },
    ],
  },
  {
    path: "customers",
    element: <Customers />,
    state: "customers",
    sidebarProps: {
      displayText: "Clientes",
      icon: <ImageSVG image={assets.images.usuarios} />,
    },
  },

  {
    path: "Suppliers",
    element: <Suppliers />,
    state: "suppliers",
    sidebarProps: {
      displayText: "Proveedores",
      icon: <ImageSVG image={assets.images.usuarios} />,
    },
  },

  {
    path: "products",
    element: <DashboardPageLayout />,
    state: "products",
    sidebarProps: {
      displayText: "Productos",
      icon: <ImageSVG image={assets.images.productos} />,
    },
    child: [
      {
        path: "/products/dishes&drinks",
        element: <Products />,
        state: "products.dishes&drinks",
        sidebarProps: { displayText: "Platos y Bebidas" },
      },
      // {
      // 	path: "/products/combos",
      // 	element: <Users />,
      // 	state: "products.combos",
      // 	sidebarProps: { displayText: "Combos" },
      // },
      {
        path: "/products/supplies",
        element: <Supplies />,
        state: "products.supplies",
        sidebarProps: { displayText: "Insumos" },
      },
      {
        path: "/products/categories",
        element: <Categories />,
        state: "products.categories",
        sidebarProps: { displayText: "Categorías" },
      },
    ],
  },

  {
    path: "inventory",
    element: <DashboardPageLayout />,
    state: "inventory",
    sidebarProps: {
      displayText: "Inventario",
      icon: <ImageSVG image={assets.images.productos} />,
    },
    child: [
      {
        path: "/inventory/movements",
        element: <Movements />,
        state: "inventory.movements",
        sidebarProps: { displayText: "Movimientos" },
      },
      {
        path: "/inventory/kardex",
        element: <Kardex />,
        state: "inventory.kardex",
        sidebarProps: { displayText: "Kardex" },
      },
    ],
  },
  {
    path: "/company",
    element: <DashboardPageLayout />,
    state: "company",
    sidebarProps: {
      displayText: "Empresa",
      icon: <ImageSVG image={assets.images.compania} />,
    },
    child: [
      {
        path: "/company/establishments",
        element: <Establishments />,
        state: "company.establishments",
        sidebarProps: { displayText: "Establecimientos" },
      },
      {
        path: "/company/rooms",
        element: <Rooms />,
        state: "company.rooms",
        sidebarProps: { displayText: "Salones & Mesas" },
      },
      {
        path: "/company/production/area",
        element: <Productions />,
        state: "company.production.area",
        sidebarProps: { displayText: "Areas de producción" },
      },
      {
        path: "/company/settings",
        element: <CompanySetting />,
        state: "company.settings",
        sidebarProps: { displayText: "Ajustes" },
      },
    ],
  },
  {
    path: "users",
    element: <Users />,
    state: "users",
    sidebarProps: {
      displayText: "Usuarios",
      icon: <ImageSVG image={assets.images.usuarios} />,
    },
  },

  {
    path: "reportes",
    element: <Reportes />,
    state: "reportes",
    sidebarProps: {
      displayText: "Reportes",
      icon: <ImageSVG image={assets.images.usuarios} />,
    },
  },

  {
    path: "/configuration",
    element: <DashboardPageLayout />,
    state: "configuration",
    sidebarProps: {
      displayText: "Configuraciones",
      icon: <ImageSVG image={assets.images.Cog} />,
    },
    child: [
      {
        path: "/configuration/catalog/sunat",
        element: <CatalogSunatSetting />,
        state: "configuration.sunat",
        sidebarProps: { displayText: "Geografía" },
      },
    ],
  },
  {
    path: "contabilidad",
    element: <DashboardPageLayout />,
    state: "contabilidad",
    sidebarProps: {
      displayText: "Contablidad",
      icon: <ImageSVG image={assets.images.productos} />,
    },
    child: [
      {
        path: "/contabilidad/accountsxPayable",
        element: <AccountsxPayable />,
        state: "contabilidad.accountsxPayable",
        sidebarProps: { displayText: "Cuentas por Pagar" },
      },
    ],
  },
];

export default appRoutes;
