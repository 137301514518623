import logoPng from "./images/logo.png";
import logoDetailPng from "./images/logo_nativa.png";
import bgCover from "./images/pattern-1.svg";
import themeModePng from "./images/theme-mode.png";
import spinnerSvg from "./images/spinner.svg";
import userJpg from "./images/users/user.jpg";
import coverJpg from "./images/covers/cover.jpg";
import noImage from "./images/noImagen.svg";
import buscarDocumento from "./images/buscar-documento.svg";
import Dashboard from "./images/dashboard.svg";
import Cog from "./images/cog.svg";
import productos from "./images/productos.svg";
import login from "./images/login.svg";
import contrasena from "./images/contrasena.svg";
import arroba from "./images/arroba.svg";
import usuarios from "./images/usuarios.svg";
import mozo from "./images/mozo.svg";
import iniciarSesion from "./images/iniciarSesion.svg";
import cerrarSesion from "./images/cerrarSesion.svg";
import visible from "./images/visible.svg";
import noVisible from "./images/noVisible.svg";
import compania from "./images/compania.svg";
import countries from "./images/countries.svg";
import department from "./images/department.svg";
import province from "./images/province.svg";
import district from "./images/district.svg";
import construction from "./images/construction.svg";
import logoRestaurantify from "./images/logo-restaurantify.png";
import logoRestaurantifyOlla from "./images/logo-restaurantify-olla.png";
import configCompany from "./images/config-company.svg";
import cogCompany from "./images/cog-company.svg";
import area from "./images/area.svg";
import loaderBox from "./images/loader-box.svg";
import loaderCircle from "./images/loader-circle.svg";
import posTerminal from "./images/pos-terminal.svg";
import error404 from "./images/error-404.svg";
import useWarning from "./images/user-warning.svg";
import backgroundScreen from "./images/background.svg";
import logovazco from "./images/logovazco.png";
import userJpeg from "./images/users/user.jpeg";
import cajaRegistradora from "./images/caja_registradora.png";
import AperturaCajaIcon from "./images/unlocked.svg";
import CierreCajaIcon from "./images/lock.svg";
import ingresoIcon from "./images/ingreso.svg";
import egresoIcon from "./images/egreso.svg";

const assets = {
  images: {
    logoPng,
    logoDetailPng,
    themeModePng,
    spinnerSvg,
    userJpg,
    coverJpg,
    bgCover,
    noImage,
    buscarDocumento,
    Dashboard,
    Cog,
    productos,
    login,
    contrasena,
    arroba,
    usuarios,
    mozo,
    iniciarSesion,
    cerrarSesion,
    visible,
    noVisible,
    compania,
    countries,
    department,
    province,
    district,
    logoRestaurantify,
    logoRestaurantifyOlla,
    construction,
    configCompany,
    cogCompany,
    area,
    loaderBox,
    loaderCircle,
    posTerminal,
    error404,
    useWarning,
    backgroundScreen,
    logovazco,
    userJpeg,
    cajaRegistradora,
    AperturaCajaIcon,
    CierreCajaIcon,
    ingresoIcon,
    egresoIcon,
  },
};

export default assets;
