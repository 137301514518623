import React, { useState, useEffect, FC } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Container,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Snackbar,
  Alert,
  
} from '@mui/material';
import { Filters } from "@ctypes/common";
import { SuppliersFiltersPayload } from '@ctypes/suppliers';
import { useSuppliers } from '@hooks/queries/useSuppliers';
import { SupplierPayload } from '@ctypes/suppliers';
import { createSupplierAPI,updateSupplierAPI } from '@api/suppliers'; 
import CenteredSnackbar from '@components/suppliers/CenteredSnackbar';

// Interfaz para el tipo de proveedor
interface Proveedor {
	id: string|number;
	nombre: string;
	ruc: string;
	telefono: string;
	direccion: string;
	representante: string;
  razonsocial:string;
  notas:string;
  telefonorepresentante:string;
  internalCode:String;
  }


  
  const ProveedorSchema = Yup.object().shape({
	nombre: Yup.string().min(2, 'Muy corto').max(50, 'Muy largo').required('Requerido'),
	ruc: Yup.string().matches(/^[0-9]+$/, 'Debe contener solo números').length(11, 'Debe tener 11 dígitos').required('Requerido'),
	telefono: Yup.string().matches(/^[0-9-]+$/, 'Formato inválido').required('Requerido'),
	direccion: Yup.string().min(5, 'Muy corta').max(100, 'Muy larga').required('Requerido'),
	representante: Yup.string().min(2, 'Muy corto').max(50, 'Muy largo').required('Requerido'),
  telefonorepresentante: Yup.string().matches(/^[0-9-]+$/, 'Formato inválido').required('Requerido'),
  });

  
type SuppliersProps = {};

const Suppliers: FC<SuppliersProps> = () => {


  const [filters, setFilters] = useState<SuppliersFiltersPayload>({
		offset: 0,
		limit: 10,
		filterBy: Filters.QUERY,
		query: "",
	});
  const { data: records, isLoading, refetch } = useSuppliers(filters);
  const [proveedores, setProveedores] = useState<Proveedor[]>([]);
  const [selectedProveedor, setSelectedProveedor] = useState<Proveedor | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [claveUnica, setClaveUnica] = useState('');
  useEffect(() => {
    const generarClaveUnica = () => {
      const ahora = new Date();
      const clave = ahora.getFullYear().toString() +
                    (ahora.getMonth() + 1).toString().padStart(2, '0') +
                    ahora.getDate().toString().padStart(2, '0') +
                    ahora.getHours().toString().padStart(2, '0') +
                    ahora.getMinutes().toString().padStart(2, '0') +
                    ahora.getSeconds().toString().padStart(2, '0');
      setClaveUnica(clave);
    };

    generarClaveUnica();
  }, []);


  useEffect(() => {
    if (records && Array.isArray(records.documents)) {
      const proveedoresTransformados: Proveedor[] = records.documents.map((record) => ({
        id: record._id,
        nombre: record.name.toString(),
        ruc: record.ruc.toString(),
        telefono: record.telefono.toString(),
        direccion: record.direccion.toString(),
        representante: record.representante.toString(),
        razonsocial:record.razonsocial.toString(),
        notas:record.notes.toString(),
        telefonorepresentante:record.telefonoRepresentante.toString(),
        internalCode:claveUnica.toString(),
      }));
      setProveedores(proveedoresTransformados);
      //setLoading(false);
    }
  }, [records]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setFilters(prev => ({
      ...prev,
      offset: newPage * rowsPerPage,
    }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFilters(prev => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      offset: 0,
    }));
  };

  const handleCreateSupplier = async (supplierData: SupplierPayload) => {
    //setLoading(true);
    try {
        const newSupplier = await createSupplierAPI(supplierData);
        
        // Transformar el objeto `Supplier` a `Proveedor` sin necesidad de `id`
        const newProveedor: Proveedor = {
            id: newSupplier._id, // MongoDB genera este ID
            nombre: newSupplier.name.toString(), 
            ruc: newSupplier.ruc.toString(),
            telefono: newSupplier.telefono.toString(),
            direccion: newSupplier.direccion.toString(),
            representante: newSupplier.representante.toString(),
            razonsocial:newSupplier.razonsocial.toString(),
            notas:newSupplier.notes.toString(),
            telefonorepresentante:newSupplier.telefonoRepresentante.toString(),
            internalCode:newSupplier.internalCode.toString()
        };
        
        setProveedores((prevProveedores) => [...prevProveedores, newProveedor]);
        setSelectedProveedor(null);
        setSnackbarMessage('Proveedor guardado exitosamente!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
        console.error('Error al crear el proveedor:', error);

        setSnackbarMessage('Error al guardar el proveedor. Inténtelo de nuevo.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
    } finally {
        //setLoading(false);
    }
};

const handleSubmit = async (values: Proveedor, { setSubmitting, resetForm }: FormikHelpers<Proveedor>) => {
  const supplierPayload: SupplierPayload = {
    name: values.nombre,
    ruc: values.ruc,
    telefono: values.telefono,
    direccion: values.direccion,
    representante: values.representante,
    razonsocial: values.razonsocial,
    notes: values.notas,
    telefonoRepresentante: values.telefonorepresentante,
    internalCode: claveUnica.toString(),
  };

  if (selectedProveedor) {
    // Lógica para actualizar el proveedor
    try {
      const updatedSupplier = await updateSupplierAPI(selectedProveedor.id, supplierPayload);

      const updatedProveedores = proveedores.map((prov) =>
        prov.id === updatedSupplier._id ? { ...prov, ...updatedSupplier } : prov
      );

      setProveedores(updatedProveedores);
      setSnackbarMessage('Proveedor actualizado exitosamente!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error al actualizar el proveedor:', error);
      setSnackbarMessage('Error al actualizar el proveedor. Inténtelo de nuevo.');
      setSnackbarSeverity('error');
    }
  } else {
    await handleCreateSupplier(supplierPayload);
  }

  setOpenSnackbar(true);
  setSubmitting(false);
  resetForm();
  setSelectedProveedor(null); // Limpiar la selección después de guardar
};



const handleNuevo = (resetForm: () => void) => {
  setSelectedProveedor(null);  // Limpiar la selección
  resetForm();  // Reiniciar el formulario a sus valores iniciales
};

 
  const handleActualizar = (proveedor: Proveedor) => {
    setSelectedProveedor(proveedor);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (isLoading ) {
    return (
      <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </Container>
    );
  }


  return (

    <Container maxWidth="lg">
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h5" gutterBottom>
        Datos del Proveedor
      </Typography>
      <Formik
       
        initialValues={selectedProveedor || { id: 0, nombre: '', ruc: '', telefono: '', direccion: '', representante: '', razonsocial: '', notas: '', telefonorepresentante: '', internalCode: '' }}
        validationSchema={ProveedorSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, isSubmitting, resetForm }) => (
          <Form>
            <Grid container spacing={2}>
           
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="nombre"
                    label="Nombre"
                    error={touched.nombre && Boolean(errors.nombre)}
                    helperText={touched.nombre && errors.nombre}
                    disabled={!!selectedProveedor}  // Inhabilita el campo si estamos en modo de actualización
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="ruc"
                    label="RUC"
                    error={touched.ruc && Boolean(errors.ruc)}
                    helperText={touched.ruc && errors.ruc}
                    disabled={!!selectedProveedor}  // Inhabilita el campo si estamos en modo de actualización
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="telefono"
                    label="Teléfono"
                    error={touched.telefono && Boolean(errors.telefono)}
                    helperText={touched.telefono && errors.telefono}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="direccion"
                    label="Dirección"
                    error={touched.direccion && Boolean(errors.direccion)}
                    helperText={touched.direccion && errors.direccion}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="representante"
                    label="Representante"
                    error={touched.representante && Boolean(errors.representante)}
                    helperText={touched.representante && errors.representante}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="razonsocial"
                    label="razonsocial"
                    error={touched.razonsocial && Boolean(errors.razonsocial)}
                    helperText={touched.razonsocial && errors.razonsocial}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="telefonorepresentante"
                    label="Telefono Representante"
                    error={touched.telefonorepresentante && Boolean(errors.telefonorepresentante)}
                    helperText={touched.telefonorepresentante && errors.telefonorepresentante}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    name="notas"
                    label="Notas"
                    error={touched.notas && Boolean(errors.notas)}
                    helperText={touched.notas && errors.notas}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                    Guardar
                  </Button> */}
                  <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                   {selectedProveedor ? 'Actualizar' : 'Guardar'}
                </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" color="secondary" fullWidth onClick={() => handleNuevo(resetForm)}>
                    Nuevo
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {/* <Button variant="outlined" color="primary" fullWidth disabled={!selectedProveedor}>
                    Actualizar
                  </Button> */}
                </Grid>
              </Grid>
            
          </Form>
        )}
      </Formik>
    </Paper>

    {/* Tabla de Proveedores */}
    <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>RUC</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>Dirección</TableCell>
              <TableCell>Representante</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proveedores.map((proveedor) => (
              <TableRow key={proveedor.id}>
                <TableCell>{proveedor.id}</TableCell>
                <TableCell>{proveedor.nombre}</TableCell>
                <TableCell>{proveedor.ruc}</TableCell>
                <TableCell>{proveedor.telefono}</TableCell>
                <TableCell>{proveedor.direccion}</TableCell>
                <TableCell>{proveedor.representante}</TableCell>
                <TableCell>
                  <Button onClick={() => handleActualizar(proveedor)}>Editar</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[1,5, 10, 25]}
          component="div"
          count={records?.totalDocuments || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {/* Snackbar para mostrar el mensaje de éxito */}
      <CenteredSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
  </Container>
   
  );
};

export default Suppliers;
