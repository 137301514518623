import { useMutation, UseMutationOptions } from "react-query";
import { APIError } from "@ctypes/common";
import { LoginFormPayload, LoginResponse, Status2FACodePayload, Status2FACodeResponse } from "@ctypes/auth";
import { useUserContext, useUserContextHandlers } from "@context/UserContext";
import { loginAPI, logoutAPI, validAuthWith2FACode } from "@api/auth";
import { useNavigate } from "react-router-dom";
import { LS_IDX_MENU_TAB } from "@utils/contants";

export const useSignIn = (options?: UseMutationOptions<LoginResponse, APIError, LoginFormPayload>) => {
	const { updateUser } = useUserContextHandlers();
	return useMutation<LoginResponse, APIError, LoginFormPayload>(
		["signIn"],
		async (data) => {
			const user = await loginAPI(data);
			const { success, message, ...u } = user;
			if (user.success) updateUser(u, data.rememberMe);
			return user;
		},
		options,
	);
};

export const useValidateAuthWith2FA = (
	options?: UseMutationOptions<Status2FACodeResponse, APIError, Status2FACodePayload>,
) => {
	const { updateUser } = useUserContextHandlers();
	return useMutation<Status2FACodeResponse, APIError, Status2FACodePayload>(
		["validateAuthWith2FACode"],
		async (data) => {
			const user = await validAuthWith2FACode(data);
			const { success, message, ...u } = user;
			if (user.success) updateUser(u, true);

			return user;
		},
		options,
	);
};

export const useSignOut = (options?: UseMutationOptions<void, void, void>) => {
	const navigate = useNavigate();
	const { clearUser } = useUserContextHandlers();

	return useMutation<void, void, void>(
		["signOut"],
		async () => {
			const storedUser = localStorage.getItem("userInfo");
			let parsedUser: LoginResponse
			if (storedUser) {
					parsedUser = JSON.parse(storedUser);
					await logoutAPI(parsedUser);
					window.localStorage.removeItem(LS_IDX_MENU_TAB);
					clearUser();
					navigate("/");
				}else{
					await logoutAPI('');
					window.localStorage.removeItem(LS_IDX_MENU_TAB);
					clearUser();
					navigate("/");
				}

		},
		options,
	);
};
