import { UseMutationOptions, useQuery, UseQueryOptions,useMutation } from "react-query";

import { ProductsFiltersPayload, ProductsResponse,ProductPartial } from "@ctypes/products";
import { APIError } from "@ctypes/common";
import { getProductsAPI,putUpdatePartialProduct } from "@api/products";

// Definir el tipo del payload de actualización
type UpdatePartialProductPayload = {
	_id: string; // ID del producto que deseas actualizar
	hasIGV?: boolean;
	purchaseUnitPrice?: number;
  };
export const useProducts = (params?: ProductsFiltersPayload, options?: UseQueryOptions<ProductsResponse, APIError>) => {
	return useQuery<ProductsResponse, APIError>(
		["products", params],
		() => {
			let payload = { ...params } as any;
			if (params?.filterBy !== undefined) {
				const { query, filterBy, ...np } = params;
				payload = { ...np } as any;
				payload[filterBy] = query ?? "";
			}
			return getProductsAPI(payload);
		},
		options,
	);
};

// Definir el hook personalizado
export const useUpdatePartialProduct = (
	options?: UseMutationOptions<ProductsResponse, APIError, UpdatePartialProductPayload>
  ) => {
	return useMutation<ProductsResponse, APIError, UpdatePartialProductPayload>(
	  (payload: UpdatePartialProductPayload) => putUpdatePartialProduct(payload),
	  {
		...options, // Opciones adicionales como onSuccess, onError, etc.
	  }
	);
  };
