 const dev = {
 	SERVICE_URL: "217.196.63.172:4002/api-tenant",
 	TENANT_HOST: "217.196.63.172:3002",
 	APP_PROTOCOL: "http",
 	// SERVICE_URL: "192.168.1.9:4002/api-tenant",
 	// TENANT_HOST: "192.168.1.9:3002",
 };
 const prod = {
 	SERVICE_URL: "217.196.63.172:4002/api-tenant",
 	TENANT_HOST: "217.196.63.172:3002",
 	APP_PROTOCOL: "http",
 };

 export const config = {
 	...(process.env.REACT_APP_ENV === "dev" ? dev : dev),
 };

//const dev = {
//  SERVICE_URL: "localhost:4002/api-tenant",
//  TENANT_HOST: "localhost:3002",
//  APP_PROTOCOL: "http",
//  // SERVICE_URL: "192.168.1.9:4002/api-tenant",
//  // TENANT_HOST: "192.168.1.9:3002",
//};
//const prod = {
//  SERVICE_URL: "217.196.63.172:4002/api-tenant",
//  TENANT_HOST: "217.196.63.172:3002",
//  APP_PROTOCOL: "http",
//};
//
//export const config = {
//  ...(process.env.REACT_APP_ENV === "dev" ? dev : dev),
//};

export default config;
